<template>
  <div class="px-3">
    <h1 class="pt-3">Program Logo</h1>
    <card>
      <div v-loading="loaders.image" class="image-container-with-icon" key="fit">
        <img class="corner-icon" src="img/svg/cross-no-color.svg" alt="cross-icon" :class="{'invisible': !closeIconVisibility}" @click="handleCloseIcon">
        <el-image
          class="img-size"
          :src="url"
          fit="fill"
          @load="handleLoad"
          alt="No Logo"
        >
          <div slot="error" class="h-100 d-flex justify-content-center align-items-center">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div class="col-6 pt-2">
        <label class="font-weight-bold">Logo Image</label>
        <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg" class="form-control mb-2" @change="onImageChange">
      </div>
    </card>
    <base-button
      class="btn-primary-action px-5 font-weight-bold"
      @click="storeImageRequest"
      :loading="loaders.upload"
    >Save</base-button>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        modal-text="This will remove Program Logo"
        @onRemove="deleteImageRequest"
      />
    </div>
  </div>
</template>

<script>
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

const RemoveModal = () => import(/* webpackChunkName: "programLogoUsRemoveModal" */ "@/components/Modals/RemoveModal.vue");

export default {
  name: "ProgramLogo",
  components: {RemoveModal},
  data() {
    return {
      loaders: {
        image: false,
        removeModal: false,
        upload: false
      },
      url: '',
      closeIconVisibility: false,
      form: {
        image: null
      }
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    handleCloseIcon(){
      this.$store.commit("showRemoveModal");
    },

    onImageChange(e) {
      this.form.image = e.target.files[0];
    },

    clearFileInput() {
      this.$refs.profileImage.value = null;
    },

    handleLoad() {
      this.closeIconVisibility = true
      this.loaders.image = false
    },

    errorRoutine(message) {
      this.$notify.error({
        title: 'Error',
        message: message
      })
    },

    successRoutine(message) {
      this.$notify.success({
        title: 'Success',
        message: message
      })
    },

    fetchImageRequest(){
      this.loaders.image = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/program-logo')
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.url = response.data.data;
        })
        .catch(() => this.errorRoutine('Unable to load image try again later'))
        .finally(() => this.loaders.image = false)
    },
    storeImageRequest(){
      let vm = this;
      this.loaders.upload = true
      var formData = new FormData();
      _.each(this.form, (value, key) => {
        formData.append(key, value);
      });

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };
      axios.post(this.$store.getters.getBaseUrl + '/api/content/program-logo/store', formData, {config})
        .then(() => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          this.successRoutine('Logo updated successfully.')
          this.fetchImageRequest()
          this.clearFileInput()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Error',
            dangerouslyUseHTMLString: true,
            message: message,
          })
        })
        .finally(()=> this.loaders.upload = false)
    },
    deleteImageRequest() {
      const vm = this
      vm.loaders.removeModal = true
      axios.delete(this.$store.getters.getBaseUrl + '/api/content/program-logo/delete')
        .then(() => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          this.successRoutine('Logo removed successfully.')
          this.url = ''
          this.closeIconVisibility = false
          this.clearFileInput()
          this.$store.commit("hideRemoveModal");
        })
        .catch(() => this.errorRoutine('Delete Unsuccessful please try again later'))
        .finally(() => vm.loaders.removeModal = false)
    }
  },
  mounted() {
    this.fetchImageRequest()
  }
}
</script>

<style scoped>

.img-size{
  width: 125px;
  height: 115px
}

</style>
